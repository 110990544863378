export default function () {
  const playerState = usePlayerState()

  function togglePlayer ():void {
    playerState.value.playing = !playerState.value.playing
  }

  function startPlayer ():void {
    playerState.value.playing = false
  }

  function stopPlayer ():void {
    playerState.value.playing = false
  }

  return {
    togglePlayer,
    startPlayer,
    stopPlayer
  }
}
