import { useState } from '#app'

interface Player {
  playing: boolean
}

export default function (): Ref<Player> {
  return useState('player', () => {
    return {
      playing: false
    }
  })
}
